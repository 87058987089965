<template>
  <DetailTemplate
    :customClass="'leave-detail detail-page'"
    v-if="getPermission('Leave:view')"
  >
    <template v-slot:sidebar-detail>
      <v-card class="h-100" elevation="0">
        <div class="d-flex px-0">
          <template v-if="pageLoading">
            <v-skeleton-loader
              class="custom-skeleton height-40px custom-skeleton-full-width"
              width="600"
              type="text"
            >
            </v-skeleton-loader>
            <v-skeleton-loader
              class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
              width="100"
              type="text"
            >
            </v-skeleton-loader>
          </template>
          <template v-else>
            <CustomStatus
              :status="leaveDetail.status"
              endpoint="leave/status"
            ></CustomStatus>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" outlined label color="blue">
                  {{ leaveDetail.leave_type }}
                </v-chip>
              </template>
              <span>Type</span>
            </v-tooltip>
          </template>
        </div>
        <div class="px-2 py-5 text-center">
          <v-skeleton-loader
            class="custom-skeleton"
            v-if="pageLoading"
            type="image"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            v-if="pageLoading"
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <template v-else>
            <v-img
              :lazy-src="$defaultProfileImage"
              :src="getProfileImage"
              aspect-ratio="1"
              max-height="150"
              max-width="150"
              class="margin-auto custom-grey-border custom-border-radius-50"
              transition="fade-transition"
            >
            </v-img>
            <h1
              class="form-title custom-nowrap-ellipsis text-capitalize mt-4 mb-0"
            >
              {{ leaveDetail.user_data.full_name }}
            </h1>
          </template>
        </div>
        <div class="">
          <table class="width-100">
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-19 color-custom-blue py-1"
              >
                User Information
              </th>
            </tr>
            <tr>
              <td
                class="py-1 font-size-18 text-capitalize font-weight-700"
                width="150"
              >
                User #
              </td>
              <td class="py-1 font-size-18 font-weight-600 text-uppercase">
                <template>
                  <v-chip small outlined label color="blue"> BA-000010 </v-chip>
                </template>
                <template v-if="false"
                  ><em class="text--secondary font-weight-500"
                    >No Leave #</em
                  ></template
                >
              </td>
            </tr>
            <tr>
              <td
                class="py-1 font-size-18 text-capitalize font-weight-700"
                width="150"
              >
                Designation
              </td>
              <td class="py-1 font-size-18 font-weight-600 text-lowercase">
                <template v-if="leaveDetail.user_data.user_designation">
                  <v-chip small outlined label color="blue">
                    {{ leaveDetail.user_data.user_designation }}
                  </v-chip>
                </template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Designation</em
                  ></template
                >
              </td>
            </tr>
            <tr>
              <td
                class="py-1 font-size-18 text-capitalize font-weight-700"
                width="150"
              >
                Email
              </td>
              <td class="py-1 font-size-18 font-weight-600 text-lowercase">
                <template v-if="leaveDetail.user_data.email">
                  <v-chip small outlined label color="blue">
                    {{ leaveDetail.user_data.email }}
                  </v-chip>
                </template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Email</em
                  ></template
                >
              </td>
            </tr>
            <tr>
              <td
                class="py-1 font-size-18 text-capitalize font-weight-700"
                width="150"
              >
                Phone
              </td>
              <td class="py-1 font-size-18 font-weight-600 text-lowercase">
                <template v-if="leaveDetail.user_data.phone">
                  <v-chip small outlined label color="blue">
                    {{ leaveDetail.user_data.phone }}
                  </v-chip>
                </template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Phone</em
                  ></template
                >
              </td>
            </tr>
            <tr>
              <td
                class="py-1 font-size-18 text-capitalize font-weight-700"
                width="150"
              >
                Probation Period
              </td>
              <td class="py-1 font-size-18 font-weight-600 text-lowercase">
                <template v-if="leaveDetail.probation_period">
                  <v-chip small outlined label color="blue">
                    {{ leaveDetail.probation_period }}
                  </v-chip>
                </template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Probation Period</em
                  ></template
                >
              </td>
            </tr>
          </table>
          <table class="width-100 mt-5" v-if="false">
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-19 color-custom-blue py-1"
              >
                Emergency Details
              </th>
            </tr>
            <tr>
              <td
                class="py-1 font-size-18 text-capitalize font-weight-700"
                width="150"
              >
                Contact leave
              </td>
              <td class="py-1 font-size-18 font-weight-600 text-lowercase">
                <template v-if="leaveDetail.user_data.full_name">
                  {{ leaveDetail.user_data.full_name }}
                </template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Contact leave</em
                  ></template
                >
              </td>
            </tr>
            <tr>
              <td
                class="py-1 font-size-18 text-capitalize font-weight-700"
                width="150"
              >
                Phone Number
              </td>
              <td class="py-1 font-size-18 font-weight-600 text-lowercase">
                <template v-if="leaveDetail.user_data.phone_number">
                  {{ leaveDetail.user_data.phone_number }}
                </template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Phone Number</em
                  ></template
                >
              </td>
            </tr>
          </table>
        </div>
      </v-card>
    </template>
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <!-- <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="100"
            type="text"
          >
          </v-skeleton-loader> -->
        </template>
        <template v-else>
          <!-- <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ leaveTitle }}
          </h1> -->
          <!-- <CustomStatus
            :status="leaveDetail.status"
            endpoint="leave/status"
          ></CustomStatus> -->
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <!-- <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="updateMoreAction('edit')"
      >
        Edit <v-icon small>mdi-pencil</v-icon>
      </v-btn> -->
      <v-btn
        depressed
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="red lighted-1"
        v-on:click="updateMoreAction('delete')"
      >
        Delete <v-icon small>mdi-delete</v-icon>
      </v-btn>
      <v-menu
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in moreActions">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        depressed
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <div class="d-flex justify-space-between py-3 mx-auto w-100">
        <v-card
          class="pa-3"
          width="24%"
          outlined
          style="
            background-color: rgb(237, 252, 237);
            border: 1px solid #28c76f;
          "
        >
          <div class="text-start mb-5">
            <span class="highlight_batch leavetype1">20 Annual Leave </span>
          </div>
          <div class="text-center">
            <v-progress-circular
              :size="100"
              :width="12"
              value="25%"
              color="#28c76f"
            >
              <h2 class="text-h5 font-weight-500">
                {{ value1 }}
              </h2>
            </v-progress-circular>
            <h2 class="text-h5 font-weight-600 mt-3">15 Remaining</h2>
            <h6 class="text-h6">5 taken</h6>
          </div>
        </v-card>
        <v-card
          class="pa-3"
          width="24%"
          outlined
          style="background-color: #fbe7e5; border: 1px solid #ea3829"
        >
          <div class="text-start mb-5">
            <span class="highlight_batch leavetype2">20 Sick Leave </span>
          </div>
          <div class="text-center">
            <v-progress-circular
              :size="100"
              :width="12"
              value="75%"
              color="#ea3829"
            >
              <h2 class="text-h5 font-weight-500">
                {{ value2 }}
              </h2>
            </v-progress-circular>
            <h2 class="text-h5 font-weight-600 mt-3">5 Remaining</h2>
            <h6 class="text-h6">15 taken</h6>
          </div>
        </v-card>
        <v-card
          class="pa-3"
          width="24%"
          outlined
          style="background-color: #f8e5d8; border: 1px solid #ef7725"
        >
          <div class="text-start mb-5">
            <span class="highlight_batch leavetype3">20 Other Leave </span>
          </div>
          <div class="text-center">
            <v-progress-circular
              :size="100"
              :width="12"
              value="50%"
              color="#ef7725"
            >
              <h2 class="text-h5 font-weight-500">
                {{ value3 }}
              </h2>
            </v-progress-circular>
            <h2 class="text-h5 font-weight-600 mt-3">10 Remaining</h2>
            <h6 class="text-h6">10 taken</h6>
          </div>
        </v-card>
        <v-card
          class="pa-3"
          width="24%"
          outlined
          style="background-color: #eaf0f7; border: 1px solid #3892f3"
        >
          <div class="text-start mb-5">
            <span class="highlight_batch leavetype4">60 Total Leave </span>
          </div>
          <div class="text-center">
            <v-progress-circular
              :size="100"
              :width="12"
              value="50%"
              color="#3892f3"
            >
              <h2 class="text-h5 font-weight-500">
                {{ value4 }}
              </h2>
            </v-progress-circular>
            <h2 class="text-h5 font-weight-600 mt-3">30 Remaining</h2>
            <h6 class="text-h6">30 taken</h6>
          </div>
        </v-card>
      </div>
      <v-tabs
        v-model="leaveTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent"
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            :key="index"
            :href="'#' + tab.key"
            :disabled="tab.disabled"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 89vh position: relative"
      >
        <v-tabs-items v-model="leaveTab">
          <v-tab-item v-if="getPermission('Leave:view')" :value="'leave'">
            <v-row>
              <v-col md="6" v-if="false">
                <table width="100%">
                  <template v-if="leaveDetail.engineer">
                    <tr>
                      <td width="300" class="pb-2 font-size-18 text-capitalize">
                        Engineer Display Name
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        {{ leaveDetail.engineer.display_name }}
                      </td>
                    </tr>
                    <tr>
                      <td width="300" class="pb-2 font-size-18 text-capitalize">
                        Engineer Email
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-lowercase"
                      >
                        {{ leaveDetail.engineer.user_email }}
                      </td>
                    </tr>
                    <tr>
                      <td width="300" class="pb-2 font-size-18 text-capitalize">
                        Engineer Phone Number
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        {{ leaveDetail.engineer.phone_number }}
                      </td>
                    </tr>
                  </template>
                  <!-- <tr>
                    <td width="300" class="pb-2 font-size-18 text-capitalize">
                      Leave Type
                    </td>
                    <td
                      class="pb-2 font-size-18 font-weight-600 text-capitalize"
                    >
                      <template v-if="leaveDetail.leave_type">{{
                        leaveDetail.leave_type
                      }}</template>
                      <template v-else
                        ><em class="text--secondary"
                          >No Leave Type</em
                        ></template
                      >
                    </td>
                  </tr> -->
                  <tr>
                    <td width="300" class="pb-2 font-size-18 text-capitalize">
                      Team Email ID
                    </td>
                    <td
                      class="pb-2 font-size-18 font-weight-600 text-lowercase"
                    >
                      <template v-if="leaveDetail.team_email_id">{{
                        leaveDetail.team_email_id
                      }}</template>
                      <template v-else
                        ><em class="text--secondary"
                          >No Team Email ID
                        </em></template
                      >
                    </td>
                  </tr>
                </table>
              </v-col>
              <v-col md="6" v-if="false">
                <table width="100%">
                  <tr>
                    <td width="200" class="pb-2 font-size-18 text-capitalize">
                      Leave #
                    </td>
                    <td
                      class="pb-2 font-size-18 font-weight-600 text-capitalize"
                    >
                      {{ leaveDetail.barcode }}
                    </td>
                  </tr>
                  <tr>
                    <td width="200" class="pb-2 font-size-18 text-capitalize">
                      Leave Duration
                    </td>
                    <td
                      class="pb-2 font-size-18 font-weight-600 text-capitalize"
                    >
                      <template v-if="leaveDetail.leave_duration">{{
                        lodash.toUpper(leaveDetail.leave_duration)
                      }}</template>
                      <template v-else
                        ><em class="text--secondary"
                          >No Leave Duration</em
                        ></template
                      >
                    </td>
                  </tr>
                  <tr>
                    <td width="200" class="pb-2 font-size-18 text-capitalize">
                      Start Date
                    </td>
                    <td
                      class="pb-2 font-size-18 font-weight-600 text-capitalize"
                    >
                      <template v-if="leaveDetail.leave_start_date">{{
                        formatDate(leaveDetail.leave_start_date)
                      }}</template>
                      <template v-else
                        ><em class="text--secondary"
                          >No Start Date</em
                        ></template
                      >&nbsp;
                      <template
                        v-if="
                          leaveDetail.leave_duration == 'half-day' &&
                          leaveDetail.leave_start_time
                        "
                        >{{
                          formatTime(leaveDetail.leave_start_time)
                        }}</template
                      >
                    </td>
                  </tr>
                  <tr>
                    <td width="200" class="pb-2 font-size-18 text-capitalize">
                      End Date
                    </td>
                    <td
                      class="pb-2 font-size-18 font-weight-600 text-capitalize"
                    >
                      <template v-if="leaveDetail.leave_end_date">{{
                        formatDate(leaveDetail.leave_end_date)
                      }}</template>
                      <template v-else
                        ><em class="text--secondary">No End Date</em></template
                      >&nbsp;
                      <template
                        v-if="
                          leaveDetail.leave_duration == 'half-day' &&
                          leaveDetail.leave_end_time
                        "
                        >{{ formatTime(leaveDetail.leave_end_time) }}</template
                      >
                    </td>
                  </tr>
                </table>
              </v-col>
              <v-col md="12" lg="12">
                <v-card
                  flat
                  class="custom-grey-border remove-border-radius mb-4 h-100"
                >
                  <v-card-title class="headline grey lighten-4">
                    <span
                      class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >
                      Overview</span
                    >
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <table width="100%">
                      <template v-if="leaveDetail.engineer">
                        <tr>
                          <td class="py-1 font-size-18 text-capitalize">
                            Engineer Display Name
                          </td>
                          <td
                            class="py-1 font-size-18 font-weight-600 text-capitalize"
                          >
                            {{ leaveDetail.engineer.display_name }}
                          </td>
                        </tr>
                        <tr>
                          <td class="py-1 font-size-18 text-capitalize">
                            Engineer Email
                          </td>
                          <td
                            class="py-1 font-size-18 font-weight-600 text-lowercase"
                          >
                            {{ leaveDetail.engineer.user_email }}
                          </td>
                        </tr>
                        <tr>
                          <td class="py-1 font-size-18 text-capitalize">
                            Engineer Phone Number
                          </td>
                          <td
                            class="py-1 font-size-18 font-weight-600 text-capitalize"
                          >
                            {{ leaveDetail.engineer.phone_number }}
                          </td>
                        </tr>
                      </template>
                      <!-- <tr>
                        <td class="py-1 font-size-18 text-capitalize">
                          Leave Type
                        </td>
                        <td
                          class="py-1 font-size-18 font-weight-600 text-capitalize"
                        >
                          <template v-if="leaveDetail.leave_type">{{
                            leaveDetail.leave_type
                          }}</template>
                          <template v-else
                            ><em class="text--secondary"
                              >No Leave Type</em
                            ></template
                          >
                        </td>
                      </tr> -->
                      <tr>
                        <td
                          class="py-1 font-size-18 text-capitalize"
                          width="200"
                        >
                          Team Email ID
                        </td>
                        <td
                          class="py-1 font-size-18 font-weight-600 text-lowercase"
                        >
                          <template v-if="leaveDetail.team_email_id">{{
                            leaveDetail.team_email_id
                          }}</template>
                          <template v-else
                            ><em class="text--secondary"
                              >No Team Email ID
                            </em></template
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="py-1 font-size-18 text-capitalize">
                          Leave #
                        </td>
                        <td
                          class="py-1 font-size-18 font-weight-600 text-capitalize"
                        >
                          <v-chip small outlined label color="blue">
                            {{ leaveDetail.barcode }}
                          </v-chip>
                        </td>
                      </tr>
                      <tr>
                        <td class="py-1 font-size-18 text-capitalize">
                          Leave Duration
                        </td>
                        <td
                          class="py-1 font-size-18 font-weight-600 text-capitalize"
                        >
                          <template v-if="leaveDetail.leave_days">
                            <v-avatar size="26" color="blue" class="text-white">
                              {{ leaveDetail.leave_days }}
                            </v-avatar>
                          </template>
                          <template v-else
                            ><em class="text--secondary"
                              >No Leave Duration</em
                            ></template
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="py-1 font-size-18 text-capitalize">
                          Start Date
                        </td>
                        <td
                          class="py-1 font-size-18 font-weight-600 text-capitalize"
                        >
                          <template v-if="leaveDetail.leave_start_date">{{
                            formatDate(leaveDetail.leave_start_date)
                          }}</template>
                          <template v-else
                            ><em class="text--secondary"
                              >No Start Date</em
                            ></template
                          >&nbsp;
                          <template
                            v-if="
                              leaveDetail.leave_duration == 'half-day' &&
                              leaveDetail.leave_start_time
                            "
                            >{{
                              formatTime(leaveDetail.leave_start_time)
                            }}</template
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="py-1 font-size-18 text-capitalize">
                          End Date
                        </td>
                        <td
                          class="py-1 font-size-18 font-weight-600 text-capitalize"
                        >
                          <template v-if="leaveDetail.leave_end_date">{{
                            formatDate(leaveDetail.leave_end_date)
                          }}</template>
                          <template v-else
                            ><em class="text--secondary"
                              >No End Date</em
                            ></template
                          >&nbsp;
                          <template
                            v-if="
                              leaveDetail.leave_duration == 'half-day' &&
                              leaveDetail.leave_end_time
                            "
                            >{{
                              formatTime(leaveDetail.leave_end_time)
                            }}</template
                          >
                        </td>
                      </tr>
                    </table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="12" class="py-0">
                <v-card
                  flat
                  class="custom-grey-border remove-border-radius mb-4 h-100"
                >
                  <v-card-title class="headline grey lighten-4">
                    <span
                      class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >
                      Reason</span
                    >
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <read-more
                      class="custom-read-more font-weight-600 font-size-18 custom-gray-color"
                      more-str="read more"
                      :text="leaveDetail.reason || '<em>No Reason</em>'"
                      link="#"
                      less-str="read less"
                      :max-chars="200"
                    >
                    </read-more>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="12" lg="12">
                <v-card
                  flat
                  class="custom-grey-border remove-border-radius h-100"
                >
                  <v-card-title class="headline grey lighten-4">
                    <span
                      class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >
                      Remark</span
                    >
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <span>
                      <template v-if="leaveDetail.remark">
                        {{ leaveDetail.remark }}
                      </template>
                      <template v-else>
                        <em class="text--secondary font-weight-500"
                          >no remark</em
                        >
                      </template>
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="12" class="pt-0">
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title
                    class="headline grey lighten-4 justify-space-between"
                  >
                    <span
                      class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >
                      Attachment</span
                    >
                    <v-btn depressed color="cyan" class="white--text"
                      ><v-icon small left>mdi-plus</v-icon>Attach File</v-btn
                    >
                  </v-card-title>
                  <v-card-text class="font-size-16">
                    <!-- <AdditionalDocuments
                      :type-id="leaveDetail.id"
                      class="mx-4"
                      type-text="Leave Documents"
                      :type="10"
                      :extensive="false"
                    ></AdditionalDocuments> -->
                    <FileTemplate
                      :allowDelete="false"
                      :attachments="attachments"
                    ></FileTemplate>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="'history'">
            <InternalHistoryDetail
              type="leave"
              :type_id="leaveDetail.id"
            ></InternalHistoryDetail>
          </v-tab-item>
          <v-tab-item :value="'logs'">
            <v-row class="supplier-detail custom-border-top mt-3">
              <v-col md="12" sm="12" class="">
                <div class="d-flex align-center justify-space-between">
                  <v-btn-toggle v-model="leaveStatusType">
                    <v-btn value="all" class="py-2">
                      <v-avatar color="blue" class="white--text mr-2" size="25"
                        >10</v-avatar
                      >
                      <span class="hidden-sm-and-down">All</span>
                    </v-btn>

                    <v-btn value="pending" class="py-2">
                      <v-avatar
                        color="orange"
                        class="white--text mr-2"
                        size="25"
                        >2</v-avatar
                      >
                      <span class="hidden-sm-and-down font-size-18"
                        >Pending</span
                      >
                    </v-btn>

                    <v-btn value="accepted" class="py-2">
                      <v-avatar
                        color="success"
                        class="white--text mr-2"
                        size="25"
                        >3</v-avatar
                      >
                      <span class="hidden-sm-and-down">Accepted</span>
                    </v-btn>

                    <v-btn value="rejected" class="py-2">
                      <v-avatar color="red" class="white--text mr-2" size="25"
                        >5</v-avatar
                      >
                      <span class="hidden-sm-and-down">Rejected</span>
                    </v-btn>
                  </v-btn-toggle>
                  <div class="d-flex">
                    <DateRangePicker
                      prop_id="duration-picker"
                      :page-loading="pageLoading"
                      prop_label="Date Range"
                      v-model="duration"
                    ></DateRangePicker>
                    <v-select
                      :items="leaveTypeData"
                      v-model="leaveType"
                      hide-details
                      class="line-item-header-text discount-type text-h6 px-0 ml-3"
                      placeholder="Type"
                      dense
                      solo
                      flat
                      style="width: 50%"
                    />
                  </div>
                </div>
              </v-col>
              <v-col md="12" sm="12" class="pt-0">
                <v-skeleton-loader
                  class="custom-skeleton"
                  v-if="pageLoading"
                  type="table-tbody"
                >
                </v-skeleton-loader>
                <v-simple-table v-else class="simple-table">
                  <template v-slot:default>
                    <thead>
                      <tr style="background-color: #f3f6f9; color: #24326d">
                        <td>
                          <v-checkbox
                            v-model="selectedAll"
                            class="hide-margin"
                            color="cyan"
                            dense
                            hide-details
                          ></v-checkbox>
                        </td>
                        <td class="font-size-13 text-uppercase font-weight-500">
                          Leave #
                        </td>
                        <td class="font-size-13 text-uppercase font-weight-500">
                          Date
                        </td>
                        <td class="font-size-13 text-uppercase font-weight-500">
                          Leave Type
                        </td>
                        <td class="font-size-13 text-uppercase font-weight-500">
                          Attachments
                        </td>
                        <td class="font-size-13 text-uppercase font-weight-500">
                          No. of Days
                        </td>
                        <td class="font-size-13 text-uppercase font-weight-500">
                          Status
                        </td>
                        <td class="font-size-13 text-uppercase font-weight-500">
                          Applied At
                        </td>
                        <td class="font-size-13 text-uppercase font-weight-500">
                          Action date
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(leave, index) in leaveData"
                        :key="index"
                        :class="{
                          'custom-border-top': index == 0,
                          'orange_lighten-5': index % 2 == 0,
                        }"
                      >
                        <td>
                          <v-checkbox
                            v-model="selectedRows"
                            class="hide-margin"
                            color="cyan"
                            dense
                            hide-details
                            v-bind:value="leave.id"
                          ></v-checkbox>
                        </td>
                        <td
                          link
                          class="font-size-14 font-weight-500 text-capitalize"
                        >
                          <v-chip outlined label color="blue">
                            {{ leave.barcode }}
                          </v-chip>
                        </td>
                        <td link class="font-size-14 font-weight-500">
                          <template v-if="leave && leave.leave_start_date">
                            <div>Start: {{ leave.leave_start_date }}</div>
                            <div>End: {{ leave.leave_end_date }}</div>
                          </template>
                          <em v-else class="text-muted"> no date</em>
                        </td>
                        <td link class="font-size-14 font-weight-500">
                          <template v-if="leave && leave.leave_type">
                            <v-chip outlined label color="orange" class="mb-1">
                              {{ leave.leave_type }}
                            </v-chip>
                            <br />
                            <v-chip outlined label color="red">
                              {{ leave.leave_status }}</v-chip
                            >
                          </template>
                          <em v-else class="text-muted"> no leave type</em>
                        </td>
                        <td link class="font-size-14 font-weight-500">
                          <template v-if="leave && leave.attachments">
                            {{ leave.attachments }}
                          </template>
                          <em v-else class="text-muted"> no attachments</em>
                        </td>
                        <td
                          link
                          class="font-size-14 font-weight-500 text-lowercase"
                        >
                          <template v-if="leave && leave.leave_duration">
                            <v-avatar
                              class="white--text"
                              color="primary"
                              size="26"
                            >
                              {{ leave.leave_duration }}
                            </v-avatar>
                          </template>
                          <em v-else class="text-muted"> no. of days</em>
                        </td>
                        <td link class="font-size-14 font-weight-500">
                          <template v-if="leave && leave.status">
                            <v-chip outlined label color="orange">
                              {{ leave.status }}
                            </v-chip>
                          </template>
                          <em v-else class="text-muted"> no status</em>
                        </td>
                        <td class="font-size-14 font-weight-500">
                          <template v-if="leave && leave.applied_at">
                            <v-chip outlined label color="green">
                              {{ leave.applied_at }}
                            </v-chip>
                          </template>
                          <em v-else class="text-muted"> no applied at</em>
                        </td>
                        <td class="font-size-14 font-weight-500">
                          <template v-if="leave && leave.action_date">
                            {{ leave.action_date }}
                          </template>
                          <em v-else class="text-muted"> no action date</em>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </perfect-scrollbar>
      <LeaveDelete
        :deleteDialog="leaveDeleteDialog"
        :requestUrl="leaveDeleteRequestUrl"
        v-on:delete:success="leaveDeleteSuccess"
        v-on:delete:close="leaveDeleteDialog = false"
      ></LeaveDelete>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus";
import FileTemplate from "@/view/pages/partials/FileTemplate";
//import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
import LeaveDelete from "@/view/pages/partials/Delete-Request-Template.vue";
import DateRangePicker from "@/view/pages/partials/DateRangePicker.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "leave-detail",
  title: "Detail Leave",

  data() {
    return {
      value1: 15,
      value2: 5,
      value3: 10,
      value4: 30,
      leaveStatusType: "all",
      leaveTab: "leave",
      leave: null,
      duration: null,
      leaveType: "Paid",
      leaveTypeData: ["Paid", "UnPaid"],
      leaveDeleteDialog: false,
      add_document_dialog: false,
      moreActions: new Array(),
      attachments: new Array(),
      pageLoading: false,
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "leave",
          disabled: false,
        },
        {
          title: "Logs",
          icon: "mdi-credit-card",
          key: "logs",
          disabled: false,
        },
        {
          title: "History",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false,
        },
      ],
      selectedAll: false,
      selectedRows: false,
      leaveData: [
        {
          id: 1,
          barcode: "LEV-000003",
          leave_duration: 2,
          leave_type: "Medical",
          leave_status: "Unpaid",
          leave_start_date: "26/12/2023",
          leave_end_date: "26/12/2023",
          leave_start_time: null,
          leave_end_time: null,
          reason: null,
          status: "Pending",
          team_email_id: null,
          attachments: null,
          applied_at: "5 Days Ago",
          action_date: "25/12/2023",
        },
      ],
      leaveDetail: new Object({
        barcode: null,
        engineer: null,
        leave_duration: null,
        leave_type: null,
        leave_start_date: null,
        leave_end_date: null,
        leave_start_time: null,
        leave_end_time: null,
        reason: null,
        status: null,
        team_email_id: null,
        attachments: [],
      }),
    };
  },
  components: {
    DetailTemplate,
    CustomStatus,
    LeaveDelete,
    FileTemplate,
    DateRangePicker,
    InternalHistoryDetail,
    //AdditionalDocuments,
  },
  methods: {
    getProfileImage() {
      if (!this.leaveDetail.profile_logo) {
        return null;
      }
      return this.leaveDetail.profile_logo.file.url;
    },
    leaveDeleteSuccess() {
      this.leaveDeleteDialog = false;
      this.goBack();
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("leave.update", {
              params: {
                id: _this.leave,
              },
            })
          );
          // _this.create_leave_dialog = true;
          break;
        case "delete":
          _this.leaveDeleteDialog = true;
          break;
        case "mark_as_approved":
          _this.updateLeave({ status: 2 });
          break;
        case "mark_as_rejected":
          _this.updateLeave({ status: 3 });
          break;
      }
    },
    updateLeave(data) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "leave/" + _this.leave,
          data: data,
        })
        .then(() => {
          _this.getLeave();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getLeave() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leave/" + _this.leave,
        })
        .then(({ data }) => {
          _this.leaveDetail = new Object({
            id: data.id,
            barcode: data.barcode,
            engineer: data.engineer,
            leave_duration: data.duration,
            leave_days: data.total_leave_days,
            leave_type: data.category,
            leave_start_date: data.start_date,
            leave_end_date: data.end_date,
            leave_start_time: data.start_time,
            leave_end_time: data.end_time,
            reason: data.reason,
            status: data.status,
            team_email_id: data.email,
            user_data: data.user_data,
            attachments: [],
          });
          _this.attachments = data.attachments;
          _this.moreActions = data.more_actions;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Leave", route: "leave" },
      { title: "Detail" },
    ]);

    _this.leave = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.leave <= 0) {
      _this.goBack();
    }

    _this.getLeave();
  },
  computed: {
    leaveDeleteRequestUrl() {
      return "leave/" + this.leave;
    },
    leaveTitle() {
      return this.leaveDetail.leave_type;
    },
  },
  created() {
    const _this = this;
    _this.leave = _this.$route.params.id;
    if (!_this.leave || _this.leave <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.leaveTab = _this.$route.query.tab;
    }
  },
};
</script>
<style scoped>
span.highlight_batch {
  display: inline-block;
  padding: 4px 15px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border-radius: 0 0 10px/0px 0 10px;
  position: relative;
  left: -12px;
  top: -12px;
}
.leavetype1 {
  background: #28c76f;
}
.leavetype2 {
  background: #ea3829;
}
.leavetype3 {
  background: #ef7725;
}
.leavetype4 {
  background: #3892f3;
}
</style>
